@use "sass:meta" as ---886wn800nib;// transition variables
$duration_short: 0.1s;
$duration: 0.3s;
$duration_long: 0.6s;
$duration_extra_long: 1s;
$duration_skeleton_loading: 1.4s;
$easing: cubic-bezier(0.3, 0.1, 0.3, 1);
$ease-in: cubic-bezier(0.75,0,0.25,1);
$ease-out: cubic-bezier(0.17,0.84,0.44,1);
$adaptives_scale: 0.92;

;@include ---886wn800nib.load-css("sass-embedded-legacy-load-done:101881");