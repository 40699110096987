@use "sass:meta" as ---886wn800nib;$f-manrope: "Manrope", sans-serif;
$f-monospace: "SFMono-Regular", "Consolas", "Liberation Mono", "Menlo", "Courier", "monospace";

$font-weight_light: 300;
$font-weight_regular: 400;
$font-weight_medium: 500;
$font-weight_semi-bold: 600;
$font-weight_bold: 700;
$font-weight_extra-bold: 800;
$font-weight_heavy: 900;

;@include ---886wn800nib.load-css("sass-embedded-legacy-load-done:114003");